<template>
  <div style="position: relative;">
    <div
      class="width bg_fa flex bewteen aline_center center size_20"
      style="height: 120px; position: relative;"
    >
      <div style="width: 390px">
        <div>{{ cnt.quota ? cnt.quota : 0 }}万</div>
        <div class="mat_20">申请总额度</div>
      </div>
      <div style="width: 390px">
        <div>{{ cnt.accept_total ? cnt.accept_total : 0 }}万</div>
        <div class="mat_20">已授信</div>
      </div>
      <div style="width: 390px">
        <div>{{ cnt.no_accept_num ? cnt.no_accept_num : 0 }}条</div>
        <div class="mat_20">待授信</div>
      </div>
      <div style="width: 390px">
        <div>{{ cnt.demand_num ? cnt.demand_num : 0 }}次</div>
        <div class="mat_20">产品申请数</div>
      </div>
    </div>

    <!-- <div class="bor_b flex mat_30" style="line-height: 50px; font-size: 14px; cursor: pointer;">
      <div style="margin-right: 100px; color: #808080;" @click='changeTags(1)'>
        <span :class="{main_text_color: menu == 1}">全部</span>
        <div :class="{product_nav: menu == 1}"></div>
      </div>
      <div style="margin-right: 100px; color: #808080;" @click='changeTags(2)'>
        <span :class="{main_text_color: menu == 2}">进行中</span>
        <div :class="{product_nav: menu == 2}"></div>
      </div>
      <div style='color: #808080;' @click='changeTags(3)'>
        <span :class="{main_text_color: menu == 3}">已完成</span>
        <div :class="{product_nav: menu == 3}"></div>
      </div>
    </div> -->

    <el-tabs v-model="activeName" @tab-click="changeTags(activeName)">
      <el-tab-pane label="全部" name="1"></el-tab-pane>
      <el-tab-pane label="进行中" name="2"></el-tab-pane>
      <el-tab-pane label="已完成" name="3"></el-tab-pane>
    </el-tabs>

    <div class="flex bewteen aline_center center wrap" style="padding: 20px 0">
      <el-form ref="form" :model="form" label-width="120px" class="flex">
        <div class="flex aline_center center wrap">
          <div style="width:30%">
            <el-form-item label="搜索：">
              <el-input
                v-model="form.name"
                placeholder="请输入企业名/产品名"
              ></el-input>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="融资期限（月）：" label-width="200px">
              <el-col :span="11">
                <el-input
                  v-model="form.min_term"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-input
                  v-model="form.max_term"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
            </el-form-item>
          </div>
          <div>
            <el-form-item label="拟融资额度（万元）：" label-width="200px">
              <el-col :span="11">
                <el-input
                  v-model="form.min_quota"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-input
                  v-model="form.max_quota"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
            </el-form-item>
          </div>

          <div style="width:30%">
            <el-form-item label="利率范围（%）：" label-width="120px">
              <el-col :span="11">
                <el-input
                  v-model="form.min_rate"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-input
                  v-model="form.max_rate"
                  placeholder="请输入数字"
                ></el-input>
              </el-col>
            </el-form-item>
          </div>
          <div style="padding-left: 50px;">
            <el-form-item label="申请时间：" label-width="150px">
              <el-col :span="11">
                <el-date-picker
                  clearable
                  type="date"
                  placeholder="开始日期"
                  v-model="form.startTime"
                  style="width: 165px;"
                  @input="clearStartTime"
                ></el-date-picker>
              </el-col>
              <el-col class="line" :span="2" style="padding: 0 12px 0 14px;"
                >-</el-col
              >
              <el-col :span="11">
                <el-date-picker
                  clearable
                  type="date"
                  placeholder="结束日期"
                  v-model="form.endTime"
                  style="width: 165px;"
                  @input="clearEndTime"
                ></el-date-picker>
              </el-col>
            </el-form-item>
          </div>
          <div style="width:33.2%; margin-right: 30px;">
            <el-form-item label="状态：" label-width="200px">
              <el-select
                v-model="form.status"
                placeholder="请选择"
                clearable
                @clear="clearStatus"
                style="width:100%"
              >
                <el-option
                  :value="item.value"
                  v-for="(item, index) in options2"
                  :key="index"
                  @click.native="setStatus(item)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div style="width:30%">
            <el-form-item label="所在地区：">
              <el-select
                v-model="form.region_id"
                placeholder="请选择"
                clearable
                @clear="clearClass"
                style="width:100%"
              >
                <el-option
                  v-for="(item, index) in options9"
                  :key="index"
                  :value="item.name"
                  @click.native="setAddressId(item.id)"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>

          <div style="display: flex; align-items: center;">
            <div style="">
              <!-- flex: 1; text-align: left -->
              <el-form-item>
                <el-button type="primary" @click.native="searchData"
                  >搜索</el-button
                >
              </el-form-item>
            </div>
          </div>
        </div>
      </el-form>
    </div>
    <!--修改模块  -->
    <el-table
      :data="listsItem"
      style="width: 100%"
      :header-cell-style="{ background: '#e5f3ff', color: '#1A7FD7' }"
    >
      <el-table-column prop="mark" label="编号"></el-table-column>
      <el-table-column prop="company_name" label="企业名称"> </el-table-column>
      <el-table-column prop="region_name" label="地区"> </el-table-column>
      <el-table-column prop="quota" label="拟融资额度（万元）" class-name="red">
      </el-table-column>
      <el-table-column prop="term" label="融资期限 （月）"> </el-table-column>
      <el-table-column prop="max_rate" label="年利率 （%）"> </el-table-column>
      <el-table-column prop="is_policy" label="政策型"> </el-table-column>
      <el-table-column prop="name" label="产品"> </el-table-column>
      <el-table-column prop="create_time" label="申请时间"> </el-table-column>
      <el-table-column prop="status" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 7">待受理</span>
          <span v-if="scope.row.status == 1">待审核</span>
          <span v-if="scope.row.status == 2">已审核</span>
          <span v-if="scope.row.status == 3">审核未通过</span>
          <span v-if="scope.row.status == 4">已驳回</span>
          <span v-if="scope.row.status == 5">已授信</span>
          <span v-if="scope.row.status == 6">已完成</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="accept_quota"
        label="授信额度（万元）"
        class-name="blue"
      ></el-table-column>
      <el-table-column prop="contacts" label="联系人"></el-table-column>
      <el-table-column prop="mobile" label="联系电话"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot-scope="scope">
          <div v-if="scope.row.status == 7">
            <span class="success_font" @click="uploadAdjunct(scope.row.id)"
              >附件下载</span
            >
            <span
              class="success_font"
              @click="companyInfo(scope.row.company_user_id)"
              >企业信息</span
            >
            <span class="success_font" @click="submitData(scope.row.id, 1)"
              >受理</span
            >
          </div>
          <div v-if="scope.row.status == 1">
            <span class="success_font" @click="uploadAdjunct(scope.row.id)"
              >附件下载</span
            >
            <span class="success_font" @click="submitData(scope.row.id, 2)"
              >通过</span
            >
            <span class="info_font" @click="submitNot(scope.row.id, 3)"
              >不通过</span
            >
          </div>

          <div v-if="scope.row.status == 2">
            <span class="success_font" @click="uploadAdjunct(scope.row.id)"
              >附件下载</span
            >
            <span class="success_font" @click="submitCredit(scope.row.id, 5)"
              >授信</span
            >
            <span class="info_font" @click="submitReturn(scope.row.id, 4)"
              >驳回</span
            >
          </div>

          <div v-if="scope.row.status == 3">
            <span class="success_font" @click="uploadAdjunct(scope.row.id)"
              >附件下载</span
            >
          </div>

          <div v-if="scope.row.status == 4">
            <span class="success_font" @click="uploadAdjunct(scope.row.id)"
              >附件下载</span
            >
          </div>

          <div v-if="scope.row.status == 5">
            <span class="success_font" @click="uploadAdjunct(scope.row.id)"
              >附件下载</span
            >
            <span class="success_font" @click="submitData(scope.row.id, 6)"
              >点击完成</span
            >
          </div>

          <div
            v-if="scope.row.status == 6"
            @click="uploadAdjunct(scope.row.id)"
          >
            <span class="success_font">附件下载</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="123" label="企业信息">
        <!--  -->

        <!--  -->
        <template slot-scope="scope">
          <el-button type="primary" @click="firmMessage(scope.row.company_user_id)"
            >企业信息</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 弹框 -->

    <firm-message
      :is-show-dialog="isShowDialog"
      :data="firmData"
    ></firm-message>

    <!--  -->

    <!-- 分页 -->
    <div class="productApp_reportscreen">
      <div class="productApp_footer">
        <div class="productApp_mar">
          <app-mypaginations
            :pagSize="paginations"
            :allData="productList"
            @setHandleCurrent="handleCurrent"
            @setHandleSize="handleSize"
            @setPage="setPage"
            @setSize="setSize"
          />
        </div>
      </div>
    </div>

    <!-- 附件下载 -->
    <el-dialog title="附件" :visible.sync="dialogUpload" width="80%">
      <div class="prodictContent">
        <div
          v-for="(item, index) in uploadInfo"
          :key="index"
          class="upload_flex"
        >
          <div>
            <div class="upload_title">{{ item.file_explain }}</div>

            <div class="upload_line">
              <div class="upload_con">{{ item.file_name }}</div>

              <p @click="loadFile(item)" class="upload_text">下载文件</p>
            </div>
          </div>
        </div>

        <div v-if="uploadInfo.length == 0">暂无附件</div>
      </div>
    </el-dialog>

    <!-- 企业信息 -->
    <el-dialog title="企业信息" :visible.sync="dialog" width="80%">
      <div class="prodictContent">
        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">企业名称：</span>
            <span class="prodict_con">{{ userInfo.company_name }}</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">统一社会信用代码：</span>
            <span class="prodict_con">{{ userInfo.credit_code }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">法人代表：</span>
            <span class="prodict_con">{{ userInfo.legal_name }}</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">法人联系电话：</span>
            <span class="prodict_con">{{ userInfo.legal_mobile }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">企业联系人：</span>
            <span class="prodict_con">{{ userInfo.company_user }}</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">联系电话：</span>
            <span class="prodict_con">{{ userInfo.company_mobile }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">电子邮箱：</span>
            <span class="prodict_con">{{ userInfo.email }}</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">从业人员：</span>
            <span class="prodict_con">{{ userInfo.people_num }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">所处阶段：</span>
            <span class="prodict_con">{{ userInfo.company_status }}</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">企业性质：</span>
            <span class="prodict_con">{{ userInfo.company_type }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">注册资本/开办资金：</span>
            <span class="prodict_con">{{ userInfo.capital }}（万元）</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">注册时间：</span>
            <span class="prodict_con">{{ userInfo.register_time }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex">
            <span class="prodict_text">注册地址：</span>
            <span class="prodict_con">{{ userInfo.register_name }}</span>
          </div>

          <div class="prodict_flex">
            <span class="prodict_text">企业所在地：</span>
            <span class="prodict_con">{{ userInfo.com_region_name }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex1">
            <span class="prodict_text">注册详细地址：</span>
            <span class="prodict_con">{{ userInfo.registers_address }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex1">
            <span class="prodict_text">所在地详细地址：</span>
            <span class="prodict_con">{{ userInfo.coms_address }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex1">
            <span class="prodict_text">企业网址：</span>
            <span class="prodict_con">{{ userInfo.company_url }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex1">
            <span class="prodict_text">服务行业：</span>
            <span class="prodict_con">{{ userInfo.server_name }}</span>
          </div>
        </div>

        <div class="prodict_line">
          <div class="prodict_flex1">
            <span class="prodict_text">是否有抵押物：</span>
            <span class="prodict_con"
              >{{ userInfo.mortgage_status == 0 ? '否' : '有' }} （{{
                userInfo.mortgage_text
              }}）</span
            >
          </div>
        </div>

        <div class="prodict_line">
          <el-table :data="userInfo.target" style="width: 100%">
            <el-table-column prop="year" label="年份" width="180">
            </el-table-column>
            <el-table-column
              prop="total_inv"
              label="总资产（万元）"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="sale_rev" label="销售收入（万元）">
            </el-table-column>
            <el-table-column prop="net_profit" label="净利润（万元）">
            </el-table-column>
            <el-table-column prop="cash_flow" label="经营性现金流量（万元）">
            </el-table-column>
            <el-table-column prop="net_flow" label="经营性净流量（万元）">
            </el-table-column>
            <el-table-column prop="flow_radio" label="流量比率（%）">
            </el-table-column>
          </el-table>
        </div>

        <div class="prodict_line2">
          <div class="prodict_text" style="margin-bottom: 12px;">
            营业执照/法人照片
          </div>
          <div>
            <el-image
              style="width: 132px; height: 132px"
              :src="userInfo.img"
              fit="fit"
              :preview-src-list="[userInfo.img]"
            ></el-image>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MyPaginations from '@/components/MyPaginations/mypaginations.vue';
import FirmMessage from '../../components/FirmMessage/FirmMessage.vue';

export default {
  data() {
    return {
      isShowDialog: false, //弹框
      dialog: false,
      dialogUpload: false,
      uploadInfo: [],
      menu: 1,
      form: {
        name: '',
        min_quota: '',
        max_quota: '',
        min_term: '',
        max_term: '',
        min_rate: '',
        max_rate: '',
        startTime: '',
        endTime: '',
        region_id: '',
        status: '',
      },
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: 'total, sizes, prev, pager, next, jumper', // 翻页属性
      },
      productList: [],
      listsItem: [],
      count: '',
      cnt: {},
      options1: [],
      cityListProps: {
        value: 'id', //匹配响应数据中的id
        label: 'name', //匹配响应数据中的name
        children: 'chilren', //匹配响应数据中的children
      },
      firmData: {},
      firmID: '',
      options2: [
        {
          value: '待授信',
          id: 1,
        },
        {
          value: '已授信',
          id: 2,
        },
        {
          value: '未授信',
          id: 2,
        },
      ],
      statusId: '',
      options9: [],
      addressId: '',
      activeName: '1',
      userInfo: {},
    };
  },
  methods: {
    firmMessage(id) {
      // console.log(id)
      // this.firmID = row.id;
      this.isShowDialog = true;
      let data = {
        company_user_id: id,
        token: localStorage.eleToken ? localStorage.eleToken : '',
      };
      this.$post('/company_info', data).then((res) => {
        console.log(res);
        this.firmData = res.data.data;
        console.log(this.firmData);
      });
    },
    loadFile(item) {
      const elt = document.createElement('a');
      elt.setAttribute('href', item.path);
      elt.setAttribute('download', item.file_name);
      elt.style.display = 'none';
      console.log('elt', elt);
      document.body.appendChild(elt);
      elt.click();
      document.body.removeChild(elt);
    },

    uploadAdjunct(id) {
      let data = {
        id,
        token: localStorage.eleToken ? localStorage.eleToken : '',
      };

      this.$post('/ask_file_lst', data).then((res) => {
        this.uploadInfo = res.data.result;
        console.log(this.uploadInfo);

        this.dialogUpload = true;
      });
    },
    companyInfo(id) {
      let data = {
        company_user_id: id,
        token: localStorage.eleToken ? localStorage.eleToken : '',
      };

      this.$post('/company_info', data).then((res) => {
        this.userInfo = res.data.data;
        console.log('this.userInfo', this.userInfo);

        this.dialog = true;
      });
    },
    submitCredit(id, type) {
      this.$prompt('请输入额度(万元)', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(?!(0[0-9]{0,}$))[0-9]{1,}[.]{0,}[0-9]{0,}$/,
        inputErrorMessage: '输入额度不正确',
      })
        .then(({ value }) => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : '',
            id,
            status: type,
            accept_quota: value,
          };

          this.$post('ask_state', data).then((res) => {
            let type = res.data.status == 1 ? 'success' : 'error';

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    submitNot(id, type) {
      this.$prompt('是否不通过该产品申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入不通过原因',
        inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
        inputErrorMessage: '不通过原因不能为空',
      })
        .then(({ value }) => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : '',
            id,
            status: type,
            comment: value,
          };

          this.$post('ask_state', data).then((res) => {
            let type = res.data.status == 1 ? 'success' : 'error';

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    submitReturn(id, type) {
      this.$prompt('是否驳回该产品申请?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPlaceholder: '请输入驳回原因',
        inputPattern: /^[\s\S]*.*[^\s][\s\S]*$/,
        inputErrorMessage: '驳回原因不能为空',
      })
        .then(({ value }) => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : '',
            id,
            status: type,
            comment: value,
          };
          console.log('data', data);

          this.$post('ask_state', data).then((res) => {
            let type = res.data.status == 1 ? 'success' : 'error';

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入',
          });
        });
    },
    submitData(id, type) {
      let font =
        type == 1
          ? '是否受理？'
          : type == 2
          ? '是否通过该产品申请?'
          : type == 3
          ? '是否不通过该产品申请?'
          : type == 4
          ? '是否驳回该产品申请?'
          : type == 6
          ? '是否完成?'
          : '';

      this.$confirm(font, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning',
      })
        .then(() => {
          let data = {
            token: localStorage.eleToken ? localStorage.eleToken : '',
            id,
            status: type,
          };

          this.$post('ask_state', data).then((res) => {
            let type = res.data.status == 1 ? 'success' : 'error';

            this.$message({
              message: res.data.msg,
              type,
            });

            if (res.data.status == 1) {
              this.getData();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          });
        });
    },
    changeTags(num) {
      this.menu = num;

      this.searchData();
    },
    clearStatus() {
      this.statusId = '';
    },
    setStatus(item) {
      this.statusId = item.id;
    },
    clearEndTime(value) {
      if (value == null) {
        this.form.endTime = '';
      }
    },
    clearStartTime(value) {
      if (value == null) {
        this.form.startTime = '';
      }
    },
    // 适用地区
    getQhCityList() {
      let data = {};

      this.$post('/qh_city_lst', data).then((res) => {
        this.options9 = res.data.result ? res.data.result : [];
      });
    },
    clearClass() {
      this.addressId = '';

      this.searchData();
    },
    setAddressId(id) {
      this.addressId = id;

      this.searchData();
    },
    setPage(data) {
      this.paginations.page_index = data;
    },
    setSize(data) {
      this.paginations.page_index = 1;
      this.paginations.page_size = data;
    },
    handleCurrent(item) {
      this.listsItem = item;
    },
    handleSize(item) {
      this.listsItem = item;
    },
    //重置分页
    resetPaginations() {
      // 总页数
      this.paginations.total = this.count;
      // 设置默认分页数据
      this.listsItem = this.productList.filter((item, index) => {
        return index < this.paginations.page_size;
      });
      console.log(this.listsItem);
    },
    searchData() {
      this.paginations.total = 1;
      this.getData();
    },
    getData() {
      let data = {
        page: this.paginations.page_index,
        limit: this.paginations.page_size,
        token: localStorage.eleToken ? localStorage.eleToken : '',
        min_quota: this.form.min_quota,
        max_quota: this.form.max_quota,
        min_term: this.form.min_term,
        max_term: this.form.max_term,
        min_rate: this.form.min_rate,
        max_rate: this.form.max_rate,
        startTime:
          this.form.startTime == '' ? '' : this.$turnTime(this.form.startTime),
        endTime:
          this.form.endTime == '' ? '' : this.$turnTime(this.form.endTime),
        status: this.statusId,
        region_id: this.addressId,
        name: this.form.name,
        group: this.menu,
      };

      console.log('data', data);

      this.$get('/ask_info', data).then((res) => {
        if (!res.data.result) {
          this.productList = [];
          this.count = 0;
        } else {
          this.cnt = res.data.result.cnt;
          this.productList =
            res.data.result.info.length == 0 ? [] : res.data.result.info;
          this.productList.map((item) => {
            if (item.is_policy == 0) {
              item.is_policy = '否';
            }

            if (item.is_policy == 1) {
              item.is_policy = '是';
            }

            item.max_rate = `${item.max_rate}~${item.min_rate}`;

            if (item.status == 7) {
              item.company_name = 'xxx';
              item.region_name = 'xxx';
              item.quota = 'xxx';
              item.term = 'xxx';
              item.max_rate = 'xxx';
              item.is_policy = 'xxx';
              item.name = 'xxx';
              item.contacts = 'xxx';
              item.mobile = 'xxx';
            }
          });
          this.cnt = res.data.result.cnt ? res.data.result.cnt : {};

          this.count = res.data.count ? res.data.count : 0;
        }
        this.resetPaginations();
      });
    },
  },
  mounted() {
    this.getData();
    this.getQhCityList();
  },
  components: {
    'app-mypaginations': MyPaginations,
    FirmMessage: FirmMessage,
  },
};
</script>

<style scoped>
.product_nav {
  width: 40px;
  height: 4px;
  background: #0d8eff;
  border-radius: 2px;
}

/*分页*/
.productApp_reportscreen {
  position: absolute;
  bottom: -11%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.productApp_footer {
  min-width: 402px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.productApp_mar {
  width: 100%;
}

.el-pagination {
  display: flex;
}

.el-pagination .el-select .el-input {
  height: 100%;
}

.el-select {
  height: 100%;
}

.el-pagination__jump {
  display: flex !important;
}

/deep/.red {
  color: #ff9d00;
}

/deep/.blue {
  color: #0d8eff;
}

/deep/.el-tabs__nav-wrap::after {
  height: 1px;
}

.prodict_line {
  display: flex;
  align-items: center;
  padding: 18px 0;
  border-bottom: 1px solid #d6d6d6;
}

.prodict_line2 {
  padding: 18px 0;
}

.prodict_flex {
  width: 50%;
  display: flex;
  align-items: center;
}

.prodict_flex1 {
  width: 100%;
}

.prodict_text {
  font-size: 16px;
  color: #1a1a1a;
}

.prodict_con {
  font-size: 16px;
  color: #616161;
}

.upload_flex {
  padding: 18px 0;
  border-bottom: 1px solid #d6d6d6;
}

.upload_line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload_title {
  margin-bottom: 12px;
  font-size: 16px;
  color: #1a1a1a;
  font-weight: bold;
}

.upload_con {
  font-size: 16px;
  color: #616161;
  width: 90%;
}

.upload_text {
  font-size: 15px;
  color: #0d8eff;
  cursor: pointer;
}
</style>

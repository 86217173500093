<template>
  <el-dialog
    width="90%"
    id="FirmMessage"
    top="50px"
    :lock-scroll="false"
    :show-close="true"
    :visible="isShowDialog"
    :before-close="btnCancel"
  >
    <el-row>
      <el-col class="firm-th" :span="24"
        ><div class="firm-tb"></div>
        企业实名认证信息</el-col
      >
      <el-col class="firm" :span="12"
        >企业名字：{{ data.enterprise_name }}</el-col
      >
      <el-col class="firm" :span="12"
        >统一社会信用代码：{{ data.credit_code }}</el-col
      >
      <el-col class="firm" :span="12">企业性质：{{ data.nature }}</el-col>
      <el-col class="firm" :span="12">注册时间：{{ data.create_time }}</el-col>
      <el-col class="firm" :span="12"
        >注册资金：{{ data.register_funds }}</el-col
      >
      <el-col class="firm" :span="12"
        >注册地址：{{ data.register_address }}</el-col
      >
      <el-col class="firm" :span="12"
        >法人电话：{{ data.legal_person_tel }}</el-col
      >
      <el-col class="firm" :span="12"
        >企业所在地：{{ data.enterprise_address }}</el-col
      >
      <el-col class="firm" :span="12"
        >单位在职人数：{{ data.employees_num }}</el-col
      >
      <el-col class="firm" :span="12"
        >所属行业：{{ data.belong_server }}</el-col
      >
      <el-col class="firm" :span="12"
        >企业联系人：{{ data.enterprise_contacts }}</el-col
      >
      <el-col class="firm" :span="12"
        >企业联系人电话：{{ data.enterprise_tel }}</el-col
      >
      <el-col class="firm" :span="24"
        >企业联系人微信：{{ data.enterprise_contacts_wechat }}</el-col
      >
      <el-col class="firm" :span="24">企业介绍：{{ data.introduce }}</el-col>
      <el-col class="firm" :span="24">主营业务：{{ data.business }}</el-col>
      <el-col class="firm" :span="24"
        >经营范围：{{ data.business_scope }}</el-col
      >
      <el-col class="firm" :span="12">企业营业执照</el-col>
      <el-col class="firm" :span="12">企业logo</el-col>
      <el-col class="firm" :span="12">
        <div @click="previewImg(data.business_license_img)">
          <el-image
            style="width: 100px; height: 100px"
            :src="data.business_license_img"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </el-col>
      <el-col class="firm" :span="12">
        <div @click="previewImg(data.logo_img)">
          <el-image
            style="width: 100px; height: 100px"
            :src="data.logo_img"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </el-col>
      <el-col class="firm-pic" :span="12">点击放大图片</el-col>
      <el-col class="firm-pic" :span="12">点击放大图片</el-col>
    </el-row>
  </el-dialog>
</template>

<script>
export default {
  props: {
    isShowDialog: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      url:
        'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
      srcList: [
        'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
        'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg',
      ],
    };
  },
  methods: {
    previewImg(src) {
      this.url = src;
      this.srcList = [src];
      console.log(src);
    },
    btnCancel() {
      this.$parent.isShowDialog = false;
      console.log(this.$parent.isShowDialog);
    },
  },
  created() {
    console.log(this.data);
    // console.log(this.isShowDialog);
  },
};
</script>
<style>
#FirmMessage .el-dialog__header {
  padding: 0px;
}
#FirmMessage .el-dialog__headerbtn {
  font-size: 40px;
  z-index: 1;
}
</style>
<style scoped>
#FirmMessage {
  /* margin-top: 15vh; */
  width: 80%;
  margin-left: 300px;
}

.firm-th {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  color: #333;
}
.firm-tb {
  margin-right: 10px;
  width: 6px;
  height: 18px;
  background-color: #0d8eff;
}
.firm-pic {
  font-size: 18px;
  padding: 15px 0;
  color: #0d8eff;
}
.firm {
  font-size: 18px;
  padding: 15px 0;
  color: #333333;
}
</style>
